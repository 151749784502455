<template>
  <div class="modal--small">
    <div class="modal-title">Удалить блок «{{ data.title }}»?</div>
    <div class="btns-group">
      <button class="btn-white--border mr-20" @click="$emit('closeModal')">
        Отмена
      </button>
      <button class="btn--green" @click="data.callbackModal()">Да</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDeleteBlock',
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.days {
  margin-bottom: 20px;
  align-items: center;
  .mb-0 {
    margin-bottom: 0;
  }
  .mr-10 {
    margin-right: 10px;
  }
  button {
    width: 20px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
